import styled from "styled-components";

export const SearchWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fafafa;
  border-top: 0.1rem solid #dfdfdf;
  padding: 2rem 0;
  margin-top: 2rem;
  gap: 1.5rem;
  flex-wrap: wrap;
  select {
    width: 15rem;
    height: 3.6rem;
    border: 0.1rem solid #dfdfdf;
    padding: 0 1.2rem;
    background-color: #fff;
    font-size: 1.4rem;
    border-radius: 0.4rem;
    appearance: none;
    position: relative;
    background: url('/image/arrow-down-icon.png') no-repeat center right 0.5rem / 2.4rem #fff;
  }
  input {
    width: 20rem;
    height: 3.6rem;
    border: 0.1rem solid #dfdfdf;
    padding: 0 1.2rem;
    background-color: #fff;
    font-size: 1.4rem;
    border-radius: 0.4rem;
    outline: none;
    &:focus {
      border: 0.2rem solid #57849e;
      caret-color: #57849e;
    }
  }
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 8.5rem;
    height: 3.6rem;
    background-color: #57849e;
    box-shadow: 0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12);
    border-radius: 0.4rem;
    img {
      width: 2rem;
      margin-right: 1rem;
    }
    span {
      font-size: 1.4rem;
      color: #fff;
    }
  }
  @media screen and (max-width: 768px) {
    padding: 1.5rem;
    gap: 1rem;
    select { 
      width: 100%;
      height: 3rem;
      border-radius: 0.2rem;
    }
    input {
      flex: 1;
      height: 3rem;
      border-radius: 0.2rem;
    }
    button {
      height: 3rem;
      border-radius: 0.2rem;
      img {
        width: 1.8rem;
        margin-right: 0.5rem;
      }
    }
  }
`
