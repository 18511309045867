import styled from "styled-components";

export const PopupWrap = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
`

export const PopupBody = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  padding: 2rem 4rem;
  border-radius: 1rem;
  transform: translateX(-50%) translateY(-50%);
  background-color: white
`

export const WGPProductTitle = styled.p`
  font-size: 2rem;
  font-weight: bold;
`

export const WGPProductCnt = styled.p`
  font-size: 1.5rem;
  font-weight: bold;
  color: #aaa;
`

export const WGPProductPrice = styled.p`
  font-size: 1.5rem;
  font-weight: bold;
  color: #aaa;
`

export const WGPInfo = styled.p`
  font-size: 1.2rem;
  color: #777
`

export const WGPWarning = styled.p`
  font-size: 1.2rem;
  color: #FF5733;
  text-align: center;
`

export const WGPTitle = styled.p`
  font-size: 2rem;
`

export const WGPInputWrap = styled.div`
  display: flex;
  gap: 1rem;
  width: 30rem;
  flex-wrap: wrap;
`

export const WGPInput = styled.input`
  border: .1rem solid #aaa;
  border-radius: .5rem;
  padding: .5rem 1rem;
  min-width: 25rem;
  width: 100%;
  font-size: 1.5rem;
`

export const WGPSelect = styled.select`
  border: .1rem solid #aaa;
  border-radius: .5rem;
  padding: .5rem 1rem;
  min-width: 25rem;
  width: 100%;
  font-size: 1.5rem;
`

export const WGPSubmit = styled.button`
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  padding: 1rem 3rem;
  background-color: #57849e;
  border-radius: 1rem;
  margin: 2rem 0 0;
  color: #FFFFFF;
  font-size: 1.5rem;
`
