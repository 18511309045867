import styled from "styled-components";

export const InputItem = styled.input`
  width: 100%;
  height: 3.2rem;
  border-bottom: 0.1rem solid rgba(0, 0, 0, 0.42);
  outline: none;
  font-size: 1.6rem;
  caret-color: #57849e;
  color: rgba(0,0,0,.87);
  &:disabled {
    background-color: #fff;
    color: rgba(0, 0, 0, 0.3);
  }
  &.dashed-line {
    border-bottom-style: dashed;
  }
  @media screen and (max-width: 500px) {
    height: 3rem;
    font-size: 1.4rem;
  }
`
