import styled from "styled-components";

export const HeaderStyle = styled.header`
  width: 100%;
  height: auto;
  border-bottom: 0.1rem solid #dfdfdf;
  .container {
    height: 14rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    #logo {
      display: block;
      /* background: url('/image/logo.png') no-repeat center / cover;
      width: 182px;
      height: 64px; */
      a {
        font-size: 3rem;
        color: #57849e;
      }
    }
    #gnb {
      ul {
        display: flex;
        align-items: center;
        gap: 2rem;
        li {
          button {
            cursor: pointer;
          }
          img{
            height: 7.9rem;
          }
        }
      }
    }
    .menu {
      ul {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 1rem;
        li {
          a, button {
            color: #888;
          }
        }
      }
      > a {
        display: block;
        background-color: #57849e;
        height: 3.6rem;
        padding: 0 1.6rem;
        border-radius: 1.8rem;
        font-size: 1.4rem;
        line-height: 3.6rem;
        font-weight: 500;
        color: #fff;
        letter-spacing: 0.3rem;
      }
    }
  }
  @media screen and (max-width: 1240px) {
    display: none;
  }
`



export const FooterWrap = styled.footer`
  width: 100%;
  height: auto;
  background-color: #f9f9f9;
  border-top: 0.1rem solid #dfdfdf;
  .footer-t {
    border-bottom: 0.1rem solid #dfdfdf;
    .container {
      overflow-x: auto;
    }
    ul {
      display: flex;
      align-items: center;
      li {
        position: relative;
        &:not(:last-child) {
          &::after {
            content: ' ';
            display: block;
            width: 0.1rem;
            height: 1.5rem;
            background-color: #dfdfdf;
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
          }
        }
        a {
          display: block;
          height: 6rem;
          line-height: 6rem;
          padding: 0 1.5rem;
          font-size: 1.5rem;
          font-weight: 500;
          color: #555;
          white-space: nowrap;
        }
        &:first-child {
          a {
            padding-left: 0;
          }
        }
      }
    }
  }
  .footer-b {
    padding: 5rem 0;
    .container {
      display: flex;
      align-items: center;
      .footer-logo {
        /* width: 168px;
        height: 60px;
        background: url('/image/logo.png') no-repeat center / cover; */
        a {
          font-size: 3rem;
          color: #57849e;
          font-weight: 700;
        }
      }
      .info-wrap {
        padding-left: 7rem;
        h4 {
          font-size: 1.8rem;
          font-weight: 500;
          color: #555;
          margin-bottom: 1.6rem;
        }
        .info {
          display: flex;
          align-items: center;
          dl {
            display: flex;
            align-items: center;
            font-size: 1.6rem;
            line-height: 1.5;
            color: #777;
            padding-right: 1rem;
            margin-right: 1rem;
            position: relative;
            &:not(:last-child) {
              &::after {
                content: '';
                display: block;
                width: 0.1rem;
                height: 1.3rem;
                background-color: #dfdfdf;
                position: absolute;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
              }
            }
            dt {
              font-weight: 700;
              margin-right: 0.5rem;
            }
          }
        }
        .copyright {
          margin-top: 1.6rem;
          font-size: 1.5rem;
          color: #aaa;
        }
      }
    }
  }
  @media screen and (max-width: 1240px) {
    .footer-b {
      .container {
        justify-content: center;
      }
    }
  }
  @media screen and (max-width: 900px) {
    .footer-b {
      .container {
        display: block;
        .info-wrap {
          padding-left: 0;
          margin-top: 3rem;
        }
      }
    }
  }
  @media screen and (max-width: 768px) {
    .footer-b {
      .container {
        .footer-logo {
          display: none;
        }
        .info-wrap {
          margin-top: 0;
          .info {
            display: block;
            dl {
              &:not(:last-child) {
                &::after {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 500px) {
    .footer-t {
      ul {
        li {
          a {
            height: 4rem;
            line-height: 4rem;
            font-size: 1.3rem;
          }
          &:not(:last-child) {
            &::after {
              height: 1.3rem;
            }
          }
        }
      }
    }
    .footer-b {
      padding: 4rem 0;
      .container {
        .info-wrap {
          h4 {
            font-size: 1.6rem;
            margin-bottom: 1.3rem;
          }
          .info {
            dl {
              font-size: 1.4rem;
            }
          }
          .copyright {
            font-size: 1.3rem;
            margin-top: 1.3rem;
          }
        }
      }
    }
  }
`
