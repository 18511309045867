import styled from "styled-components";

export const MyProfileWrap = styled.div`
  flex: 1;
  .profile-wrap {
    .info-box {
      display: flex;
      align-items: center;
      gap: 2rem;
      &:not(:last-child) {
        margin-bottom: 2rem;
      }
      > span {
        font-size: 1.6rem;
        font-weight: 500;
        display: block;
        padding-top: 1rem;
      }
      &.w50 {
        width: 50%;
      }
    }
  }
  .btn-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 5rem;
    padding-top: 2rem;
    border-top: 0.1rem solid #dfdfdf;
    button {
      width: 8rem;
      height: 4.4rem;
      border-radius: 0.4rem;
      font-size: 1.4rem;
      color: #fff;
      box-shadow: 0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12);
      &:first-child {
        background-color: #9e9e9e;
      }
      &:last-child {
        background-color: #57849e;
      }
    }
  }
  @media screen and (max-width: 768px) {
    width: 100%;
    .profile-wrap {
      .info-box {
        flex-wrap: wrap;
        &.w50 {
          width: 100%;
        }
        &.mo-db {
          display: block;
          > label {
            &:not(:first-child) {
              margin-top: 2rem;
            }
          }
        }
        &.email-wrap {
          label {
            &:last-child {
              width: 100%;
              margin-top: 0;
              flex: unset !important;
              select {
                padding: 0;
              }
              span {
                right: 0;
              }
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 500px) {
    .profile-wrap {
      .info-box {
        > span {
          font-size: 1.4rem;
        }
      }
    }
    .btn-wrap {
      padding-top: 2rem;
      margin-top: 4rem;
      button {
        height: 4rem;
      }
    }
  }
`

export const TitleWrap = styled.div`
  padding: 5rem 0 6rem;
  h2 {
    font-size: 2.8rem;
    font-weight: 500;
    text-align: center;
  }
  @media screen and (max-width: 768px) {
    padding: 0 0 6rem;
    h2 {
      font-size: 2.5rem;
    }
  }
  @media screen and (max-width: 500px) {
    padding: 0 0 3rem;
    h2 {
      font-size: 2.2rem;
    }
  }
`
