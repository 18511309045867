import React, {useCallback, useState} from 'react'
import {Link} from 'react-router-dom'
import moment from 'moment'

import {useRecoilValue, useSetRecoilState} from 'recoil'
import {loaderAtom} from '../../../store/recoil/loader'
import {AuthAction} from '../../../store/action'

import styled from 'styled-components'
import {Base} from '../../layout/default/style/Base'
import {Common} from '../../layout/default/style/Common'

import InputField from '../../module/input'
import LoadingButton from '../../module/loadingButton'
import Checkbox from '../../module/checkbox'
import Social from '../../module/social'
import Alert from '../../module/alert'
import {alertAtom} from '../../../store/recoil/alert'

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  overflow-y: scroll;
`

const Login = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 32rem;
  width: 40rem;
  margin: auto;
`

const Form = styled.form`
  display: flex;
  flex: 1;
  align-self: stretch;

  /*******************
  input component 스타일 재설정
  ********************/

  input[type=text],
  input[type=password] {
    border-radius: 3rem;
    padding-left: 2.2rem;

    ~ label {
      font-size: 1.4rem;
      left: 1.2rem;
    }
  }
`

const Fieldset = styled.fieldset`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-self: stretch;
`

const Option = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  margin-top: 1rem;
  margin-bottom: .5rem;
`

const Forgot = styled.div`
  margin-top: 5rem;
  text-align: center;

  a {
    color: #666666;
    display: inline-block;
    font-size: 1.3rem;
    font-weight: 500;
  }

  span.sep {
    display: inline-block;
    padding: 0 1rem;
    font-size: 1rem;
    font-weight: 600;
  }
`

const Copy = styled.div`
  font-size: 1.2rem;
  font-weight: 400;
  color: #999999;
  text-align: center;
  margin-top: 5rem;
`

const Logo = styled(Link)`
  display: block;
  /* background-image: url('../../../image/logo.png'); */
  /* background-position: left;
  background-size: 15rem;
  background-repeat: no-repeat;
  text-indent: -999999999rem; */
  /* height: 9rem;
  width: 15rem; */
  margin-bottom: 4rem;
  font-size: 2rem;
  font-weight: 700;
  color: #222;
`

const Index = () => {

  const loader = useRecoilValue(loaderAtom)
  const setAlert = useSetRecoilState(alertAtom)
  const {authAdminLogin} = AuthAction()
  const [state, setState] = useState<any>({
    email: '',
    password: '',
    submitted: false,
    rememberEmail: false
  })
  const handleChange = useCallback((e: any) => {
    setState({
      ...state,
      [e.target.name]: e.target.value
    })
  }, [state])

  const handleSubmit = (e: any) => {
    if (e) {
      e.preventDefault()
    }

    const {email, password} = state
    setState({
      ...state,
      submitted: true
    })

    if (email && password) {
      authAdminLogin(email, password)
    }
  }

  return (
    <>
      <Base/>
      <Common/>
      <Container>
        <Login>
          <Logo to={'/'}>
            쿠폰나라
          </Logo>
          <Form name="form" onSubmit={handleSubmit}>
            <Fieldset>
              <InputField
                useAnimation={true}
                type={'text'}
                id="email"
                name="email"
                placeholder="이메일을 입력하세요"
                onChange={(e) => handleChange(e)}
                check={true}
              />

              <InputField
                useAnimation={true}
                type={'password'}
                id="password"
                name="password"
                placeholder="비밀번호"
                onChange={(e) => handleChange(e)}
              />

              <Option>
                <Checkbox
                  id={'rememberEmail'}
                  checked={state.rememberEmail}
                  onChange={(e) => setState({
                    ...state,
                    rememberEmail: !state.rememberEmail
                  })}
                  label={'이메일 기억'}
                />
              </Option>

              <LoadingButton
                loading={false}
              >
                로그인
              </LoadingButton>
            </Fieldset>
          </Form>
        </Login>
        <Alert/>
      </Container>
    </>
  )
}

export default Index

